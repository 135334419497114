/* General */

.body-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Lato;
}

 /* created h1 for ada compliance */
h1 {
    font-family: Lato;
    font-weight: 700;
    font-size: 36px;
}

h2 {
    font-family: Lato;
    font-weight: 700;
    font-size: 36px;
}

h3 {
    font-family: Lato;
    font-weight: 700;
    font-size: 20px;
}

li {
    cursor: pointer;
}

a {
    cursor: pointer;
}

button {
    cursor: pointer;
}

button:focus {
    outline-style: solid;
    outline-width: 3px;
    outline-color: #347BA2;
}

.header-footer-design-element {
    background-color: #e84d4d;
    width: 100%;
    height: 25px;
    text-align: center;
    color: white;
    font-size: 14px;
    padding-top: 4px;
}

/* Nav */

.nav-container {
    font-family: Lato;
    font-weight: 700;
    font-size: 14px;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 85%;
    display: flex;
    align-items: center;
    max-width: 1200px;
}

.mastertoys-logo {
    width: 175px;
}

.nav-menu-container {
    margin-left: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.nav-menu-container button {
    width: fit-content;
    text-wrap: nowrap;
    cursor: pointer;
    padding: 7px 15px;
    border-radius: 5px;
}

.nav-menu-container button:hover {
    background-color: rgb(238, 238, 238);
}

.nav-links-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* shop now dropdown */

.nav-shopnow-dropdown-container {
    position: relative;
    display: inline-block;
    text-align: center;
    border-radius: 5px 5px 0 0 !important;
    width: 94px !important;
}

.nav-shopnow-dropdown-content {
    display: none;
    position: absolute;
    left: 0;
    border-radius: 5px;
    width: inherit;
    z-index: 1;
    background-color: rgb(238, 238, 238);
    border-radius: 0 0 5px 5px !important;
}

.nav-shopnow-dropdown-content a {
    display: block;
    padding: 7px 15px;
}

.nav-shopnow-dropdown-content a:last-child {
    border-radius: 0 0 5px 5px;
}

.nav-shopnow-dropdown-container:hover .nav-shopnow-dropdown-content {
    display: block;
}

.nav-shopnow-dropdown-container:hover .shopnow-button {
    background-color: rgb(238, 238, 238);
    border-radius: 5px 5px 0px 0px;
}

.nav-shopnow-dropdown-content a:hover {
    background-color: #347BA2;
}

.nav-login-container {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-register-button {
    color: white;
    background-color: #347BA2;
    padding: 7px 15px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.nav-register-button:hover {
    color: black;
    background-color: rgb(238, 238, 238) !important;
}

/* Skip to content for accessiblity */
/* .skip-to-content-link {
    position: absolute;
    left: 100%;
    transform: translateY(-100%);
} */
.skip-to-content-link {
    position: absolute;
    left: -9999px;
    z-index: 999;
    padding: 1em;
    background-color: black;
    color: white;
    opacity: 0;
}
  .skip-to-content-link:focus {
    left: 50%;
    top: 150px;
    transform: translateX(-50%);
    opacity: 1;
}

/* Hamburger */

.nav-hamburger-icon {
    display: none;
}

.hamburger-container {
    margin-top: 175px;
    background-color:white;
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 1;
}

.close-hamburger-button {
    display: block;
    padding: 5px 5px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: 25px;
    margin-bottom: 15px;
}

.close-hamburger-button:hover {
    background-color: #347BA2;
}

.hamburger-list > a {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 13px 0;
    cursor: pointer;
}

.hamburger-list > a:hover {
    color: white;
    background-color: #347BA2;
}

/* Media Queries for Nav Bar */

@media (max-width: 900px) {
    .nav-container {
        margin: 25px 0;
    }

    .nav-menu-container {
        justify-content: end;
    }

    .nav-links-container {
        display: none;
    }

    .mastertoys-logo {
        width: 125px;
    }

    .hamburger-container {
        margin-top: 115px;
    }

    .nav-login-container {
        display: none;
    }

    .nav-hamburger-icon {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 650px) {
    .mastertoys-logo {
        width: 100px;
    }
}

/* Slideshow */

.slideshow {
    position: relative;
    margin: 0 auto;
    width: 85%;
    height: 40vw;
    max-width: 1202px;
    max-height: 580px;
    border-radius: 40px;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.slide {
    display: inline-block;
    width: 100%;
    height: 580px;
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 40px;
}

.slideshowSlider {
    white-space: nowrap;
    transition: 1000ms;
}

.slideshow-shopnow-button {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    text-align: center;
    font-weight: 700;
    right: 0;
    font-size: 20px;
    color: white;
    background-color: #e84d4d;
    padding: 7px 25px;
    box-shadow: 5px 5px 5px 0px black;
}

.slideshow-dots {
    text-align: center;
}

.slideshow-dot {
    display: inline-block;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px 7px;
    background-color: #c4c4c4;
}

.slideshow-dot.active {
    background-color: black;
}

/* Media Queries for Slideshow */

/* @media (max-width: 900px) {
    .slideshow {
        width: 100%;
        border-radius: 0;
    }

    .slide {
        border-radius: 0;
    }

    .slideshow-dot {
        height: 12px;
        width: 12px;
    }
} */

@media (max-width: 650px) {
    .slideshow {
        width: 100%;
        height: 50vw;
        border-radius: 0;
    }

    .slide {
        border-radius: 0;
        height: 50vw;
        background-position: center;
        background-size: cover;
    }

    .slideshow-dot {
        height: 9px;
        width: 9px;
    }

    .slideshow-shopnow-button {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 115px;
        text-align: center;
        font-weight: 700;
        right: 0;
        font-size: 16px;
        color: white;
        background-color: #e84d4d;
        padding: 7px 20px;
        box-shadow: 5px 5px 5px 0px black;
    }
}

/* Main */

.main-container {
    margin: 50px 0px;
    max-width: 1200px;
    width: 85%;
}

.main-sections {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 425px;
}

/* for background color in 2nd main section */

.main-middle-section-background {
    box-shadow: 0 0 0 100vmax #D9D9D9;
    clip-path: inset(0 -100vmax);
}

.main-inverse-background {
    background-color: #D9D9D9;
}

.main-section-columns {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 360px;
}

.main-section-images {
    width: 360px;
    height: 300px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.main-section-buttons {
    position: relative;
    color: white;
    font-size: 1.2rem;
    background-color: #347BA2;
    padding: 9px 19px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: fit-content;
}

.main-section-buttons:hover {
    color: black;
    background-color: rgb(238, 238, 238) !important;
}

.main-section-form-input {
    width: 300px;
    border: 1px solid #D9D9D9;
    box-shadow: none;
}

/* Coming Soon */
.coming-soon {
    background-color: grey;
}

.coming-soon:hover {
    color: white;
    background-color: grey !important;
    cursor: default;
}

.main-section-buttons.shopnow-button {
    width: 130px;
}

.main-shopnow-dropdown-container {
    position: relative;
    display: inline-block;
    text-align: center;
    border-radius: 5px 5px 0 0 !important;
    width: 130px;
}

.main-shopnow-dropdown-content {
    display: none;
    position: absolute;
    left: 0;
    border-radius: 5px;
    width: inherit;
    z-index: 1;
    background-color: rgb(238, 238, 238);
    border-radius: 0 0 5px 5px !important;
}

.main-shopnow-dropdown-container:hover .shopnow-button {
    box-shadow: none;
    color: black;
    border-radius: 5px 5px 0 0 !important;
    background-color: rgb(238, 238, 238) !important;
}

.main-shopnow-dropdown-content a {
    display: block;
    padding: 9px 19px;
    font-size: 1.2rem;
}

.main-shopnow-dropdown-content a:last-child {
    border-radius: 0 0 5px 5px;
}

.main-shopnow-dropdown-container:hover .main-shopnow-dropdown-content {
    display: block;
}

.main-shopnow-dropdown-content a:hover {
    background-color: #347BA2;
}

/* Media Queries for Main */

@media (max-width: 900px) {
    .main-container {
        width: 95%;
        margin: 20px 0;
    }

    .main-section-buttons {
        font-size: 1rem;
        padding: 7px 15px;
    }

    .main-section-buttons.shopnow-button {
        width: 110px;
    }

    .main-shopnow-dropdown-container {
        width: 110px;
    }

    .main-shopnow-dropdown-content a {
        font-size: 1rem;
        padding: 7px 15px;
    }
}

@media (max-width: 830px) {
    h2 {
        font-size: 1.5rem;
    }

    .main-sections {
        text-align: center;
        flex-direction: column;
        height: 530px;
        justify-content: space-evenly;
    }
    
    .main-section-columns {
        width: 100%;
        align-items: center;
    }
    
    .main-section-images {
        width: 275px;
        height: 220px;
    }

    .main-middle-section-background .main-section-columns:nth-child(2) {
        order: -1;
    }
    
    .main-section-buttons.shopnow-button {
        width: 105px;
    }

    .main-shopnow-dropdown-container {
        width: 105px;
    }
}

/* Footer */

.footer-titles {
    margin-bottom: 25px;
}

.footer-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-evenly;
}

.footer-sections {
    margin: 50px 0;
    width: 30vw;
    max-width: 275px;
    color: white;
}

@media (max-width: 830px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-sections {
        max-width: 500px;
        width: 95%;
        margin: 20px 0;
    }
}

/* for background color in footer*/

.footer-section-background {
    box-shadow: 0 0 0 100vmax #347BA2;
    clip-path: inset(0 -100vmax);
}

.footer-inverse-background {
    background-color: #347BA2;
}

input, textarea {
    text-indent: 10px;
    background-color: white;
    color: black;
    height: 35px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

input::placeholder, textarea::placeholder {
    color: grey;
}

textarea {
    height: 100px;
    text-indent: 0;
    padding-top: 7px;
    padding-left: 10px;
}

.form-container * {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;
}

.footer-submit-button {
    position: relative;
    color: white;
    background-color: #e84d4d;
    padding: 7px 15px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: fit-content;
}

.footer-submit-button:hover {
    color: black;
    background-color: rgb(238, 238, 238) !important;
}

/* submit button loading animation */
.button--text {
    margin-bottom: 0;
    transition: all 0.2s;
}

.button--loading .button--text {
    visibility: hidden;
    opacity: 0;
}

.button--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}

.sitemap-container {
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.sitemap-container * {
    margin-bottom: 15px;
}

.sitemap-container > li {
    cursor: auto;
}

.footer-social-container {
    display: flex;
    flex-direction: column;
}

.footer-social-container > * {
    margin-bottom: 20px;
}

.small-mastertoys-logo {
    width: 125px;
    margin-bottom: 40px;
}

.fa-margin-right {
    margin-right: 15px;
}